import React from "react"
import { Link, graphql } from "gatsby"

import Header from "../components/Header"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "prismjs/themes/prism-tomorrow.css"

class Projects extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const projects = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Side projects and experiments with fun tech" />
        <Bio />

        <Header />

        <div className="mt-16 md:mt-28 mb-20">
          <h2 className="mb-10">Projects</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {projects.reverse().map(({ node }) => {
              const fr = node.frontmatter
              const fi = node.fields
              const mainLink = `/projects${fi.slug}`

              return (
                <div className="rounded-lg border overflow-hidden">
                  <div>
                    <a href={mainLink}>
                      <img
                        className="h-44 w-full object-cover"
                        src={fr.image}
                      />
                    </a>
                  </div>
                  <div className="p-3">
                    <a className="underline text-dark" href={mainLink}>
                      <h3>{fr.title}</h3>
                    </a>
                    <p>{fr.description}</p>
                    <div className="flex items-center gap-2 mb-3">
                      {fr.github ? (
                        <a
                          href={fr.github}
                          className="no-underline shadow-none"
                        >
                          <img
                            width="24"
                            src="https://res.cloudinary.com/jaygould/image/upload/v1660426732/blogs/gtihub-icon.svg"
                          />
                        </a>
                      ) : null}
                      {fr.website ? (
                        <a
                          href={fr.website}
                          className="no-underline shadow-none"
                        >
                          <img
                            width="24"
                            src="https://res.cloudinary.com/jaygould/image/upload/v1660427657/blogs/website-icon.svg"
                          />
                        </a>
                      ) : null}
                      {fr.relatedBlog ? (
                        <a
                          href={fr.relatedBlog}
                          className="no-underline shadow-none"
                        >
                          <img
                            width="24"
                            src="https://res.cloudinary.com/jaygould/image/upload/v1660490182/blogs/blog-post-icon.svg"
                          />
                        </a>
                      ) : null}
                    </div>
                    {fr.tech &&
                      fr.tech.map((tec) => {
                        return (
                          <div className="inline-block p-1 bg-lightBlue-200 text-xs mx-0.5 my-0.5 rounded-sm">
                            {tec}
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { glob: "**/projects/*" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
            tech
            image
            github
            website
            relatedBlog
          }
        }
      }
    }
  }
`
